@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;900&family=Oswald:wght@400;700&display=swap");

/* Base Styles */
.Event-Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8; /* Light background color */
  padding: 0% 18%;
  border-radius: 10px; /* Rounded corners for the header */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}
.maintenance-banner {
  background-color: #ffcc00;
  color: #000;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
}


#Event-Title {
  font-size: 40px;
  color: #d90000;
  margin: 10px 0; /* Adjust margins */
  font-family: "Oswald", sans-serif;
  text-align: center; /* Centered title */
}

.header-content {
  display: flex;
  justify-content: space-between; /* Distributes space evenly */
  width: 100%; /* Full width of the container */
  align-items: center; /* Center vertically */
}

.tabs-row {
  display: flex;
  margin-top: -15px; /* Adjusted margin */
  justify-content: space-between; /* Distributes space evenly between tabs */
  align-items: center; /* Centers tabs vertically */
  padding: 10px 0; /* Space around the row */
  width: 100%; /* Full width of the container */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.tab-link {
  color: black; /* Text color */
  text-decoration: none; /* Remove underline */
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-size: 16px; /* Default font size */
  padding: 10px 15px; /* Padding around links */
  border-radius: 5px; /* Rounded corners */
  margin-bottom: -20px;
}

.hamburger {
  display: none; /* Hidden by default */
  flex-direction: column;
  cursor: pointer; /* Cursor changes to pointer */
  gap: 5px; /* Space between lines */
}

.line {
  width: 25px; /* Line width */
  height: 3px; /* Line height */
  background-color: black; /* Line color */
  transition: 0.3s; /* Transition for smooth effect */
}

/* Active lines for animation */
.line.active {
  background-color: #d90000; /* Change color for active state */
}

hr {
  border: 0; /* Remove default border */
  border-top: 5px solid #d90000; /* Adjust thickness and color */
  margin: 20px 0; /* Space above and below the line */
  width: 90%; /* Full width of the container */
}

/* Mobile Menu */
.mobile-menu {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items */
  background-color: #f8f8f8; /* Same background as header */
  width: 100%; /* Full width */
  padding: 10px 0; /* Padding around menu */
  position: absolute; /* Positioned relative to the header */
  top: 80px; /* Adjust as needed based on header height */
  left: 0; /* Align to the left */
  border-radius: 0 0 10px 10px; /* Rounded corners at the bottom */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  z-index: 1; /* Ensure it overlays other content */
}

.mobile-tab-link {
  color: black; /* Text color */
  text-decoration: none; /* Remove underline */
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-size: 20px; /* Font size for mobile */
  padding: 10px 15px; /* Padding around links */
  transition: background-color 0.3s; /* Smooth background color transition */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .tabs-row {
    display: none; /* Hide tabs on mobile */
  }

  .hamburger {
    display: flex; /* Show hamburger on mobile */
  }

  #Event-Title {
    font-size: 32px; /* Adjust title size for smaller screens */
    margin: 10px 0; /* Adjust margins for mobile */
  }

  .mobile-menu {
    display: none; /* Hide menu by default */
    position: absolute;
    top: 80px; /* Adjust menu positioning */
    left: 0;
    right: 0;
  }

  .mobile-menu.active {
    display: flex; /* Show menu when hamburger is clicked */
  }

  .mobile-tab-link {
    font-size: 18px; /* Smaller font size for mobile tabs */
    padding: 12px 15px; /* Padding adjustment for smaller screens */
    width: 100%; /* Full width for better click areas */
    text-align: center; /* Center text for mobile */
  }
}

@media (max-width: 500px) {
  #Event-Title {
    font-size: 28px; /* Further reduce title size for mobile */
  }

  .mobile-tab-link {
    font-size: 16px; /* Smaller font size for mobile tabs */
    padding: 10px 12px; /* Smaller padding for mobile */
  }
  .event-menu-hr {
    display: none;
  }

  .hamburger .line {
    width: 20px; /* Smaller hamburger lines */
    height: 2px; /* Adjust hamburger line height */
  }
}
