/* UpcomingEvents.css */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

.edialogues-container {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px;
  padding: 0 12%;
}
.event-dialogue-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent */
}

.edialogues-left {
  padding-left: 5%;
  width: 50%; /* Default width for larger screens */
}

.edialogues-left div {
  border-bottom: 2px solid black; /* Adds a dotted border at the bottom */
  padding-bottom: 15px; /* Adds padding to the bottom */
  margin-bottom: 15px; /* Adds spacing between items */
}

.event-item {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.event-dialogue-item {
  font-family: "Oswald", sans-serif;
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.event-dialogue-item-para {
  font-family: "Oswald", sans-serif;
  margin-bottom: 10px;
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.dotted-separator {
  border-bottom: 2px solid black;
  margin: 20px 0;
}

.edialogues-right {
  flex: 0.5; /* Default size for larger screens */
}

.edialogues-right img {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.3s ease; /* Smooth scaling effect */
}

.event-dialogue-image {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px; /* Optional: add some styling to the image */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .edialogues-left {
    width: 100%; /* Full width on medium screens */
    padding-left: 0; /* Remove left padding */
  }

  .edialogues-right {
    width: 100%; /* Full width on medium screens */
  }
}

@media (max-width: 480px) {
  .event-dialogue-item {
    font-size: 20px; /* Smaller font for mobile */
  }

  .event-dialogue-item-para {
    font-size: 18px; /* Smaller font for mobile */
  }
}
