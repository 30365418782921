@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;900&family=Oswald:wght@400;700&display=swap");

.main-footer {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
  justify-content: space-between;
  align-items: center; /* Center align items */
  padding: 20px 10%; /* Add padding on the sides */
  background-color: #f8f8f8; /* Light background for the footer */
  border-top: 1px solid #ccc; /* Top border for separation */
}

.footer-logo-container {
  flex: 1; /* Flex grow for logo */
}

.footer-logo {
  max-width: 150px; /* Limit logo width */
  height: auto; /* Maintain aspect ratio */
}

.footer-links {
  display: flex;
  flex: 2; /* Allow more space for links */
  justify-content: space-between; /* Space between link containers */
  margin: 0 20px; /* Margin between logo and links */
}

.footer-item-links-container {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  gap: 8px; /* Reduced gap between links */
}

.footer-item-links {
  text-decoration: none;
  font-family: "Oswald", sans-serif;
  color: black;
  font-size: 18px; /* Consistent font size */
  transition: color 0.3s; /* Smooth color change */
}

.footer-item-links:hover {
  color: #ec2121; /* Change color on hover */
}

.footer-subscribe {
  flex: 1; /* Flex grow for subscribe section */
  text-align: center;
  margin-top: 20px; /* Margin for separation */
}

.footer-subscribe h2 {
  font-size: 20px; /* Heading size */
  margin-bottom: 10px; /* Space below heading */
}

.footer-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 80%; /* Full width for input */
  margin-bottom: 10px; /* Space below input */
}

.footer-button {
  padding: 10px 20px;
  background-color: #ec2121;
  color: white;
  border: none;
  font-family: "Oswald", sans-serif;
  border-radius: 4px;
  cursor: pointer;
  width: 80%; /* Full width for button */
}

.footer-button:hover {
  background-color: black;
  color: white;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-logo {
    max-width: 120px; /* Smaller logo for mobile */
  }

  .footer-item-links {
    font-size: 16px; /* Smaller text */
  }

  .footer-subscribe h2 {
    font-size: 18px; /* Smaller heading */
  }
}

@media (max-width: 480px) {
  .main-footer {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: center; /* Center align items */
    padding: 10px; /* Reduce padding */
  }

  .footer-links {
    flex-direction: column; /* Stack link containers */
    align-items: center; /* Center align links */
    margin: 0; /* Remove margins */
  }

  .footer-item-links-container {
    align-items: center; /* Center align links in containers */
  }

  .footer-input {
    width: 90%; /* Full width for input */
  }

  .footer-button {
    width: 90%; /* Full width for button */
  }
}
