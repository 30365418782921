@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;900&family=Oswald:wght@400;700&display=swap");

/* Base Styles */
.Featured-Heading {
  padding-left: 17%;
  padding-right: 16%;
  margin-top: 2%;
}

.Featured-Container {
  display: flex;
  gap: 2%;
  overflow-x: auto; /* Enable horizontal scrolling for small screens */
  scrollbar-width: thin; /* Thin scrollbar for modern look */
}

.Featured-Container::-webkit-scrollbar {
  height: 8px;
}

.Featured-Container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.Featured-video-item {
  flex: 0 0 30%; /* Each item takes 30% of the container width */
  max-width: 360px; /* Maximum width for the video item */
  display: flex;
  justify-content: center;
}

.Featured-video-frame iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Add subtle shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.Featured-video-frame iframe:hover {
  transform: scale(1.02); /* Slightly enlarge on hover */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25); /* Enhance shadow on hover */
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .Featured-Container {
    gap: 4%; /* Increase spacing on smaller screens */
  }

  .Featured-video-item {
    flex: 0 0 40%; /* Items take up more space */
  }
}

@media (max-width: 768px) {
  .Featured-video-item {
    flex: 0 0 60%; /* Stack items closer for small screens */
  }

  .Featured-Container {
    gap: 5%;
  }
}

@media (max-width: 480px) {
  .Featured-Container {
    gap: 8%; /* Even spacing for small screens */
  }

  .Featured-video-item {
    flex: 0 0 80%;
  }
}
