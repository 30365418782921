@import url("https://fonts.googleapis.com/css2?family=ABeeZee&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif&display=swap");

.book-reviews-container {
  display: flex;
  flex-direction: column;
  padding: 20px 15%;
  max-width: 100%;
  margin: auto;
}

/* First review layout */
.first-review {
  display: flex;
  flex-direction: row; /* Row layout for larger screens */
  margin-bottom: 40px;
  padding: 0 2%;
  padding-bottom: 20px;
  align-items: stretch;
  transition: transform 0.3s ease;
}

.first-review-content {
  flex: 1;
  padding: 0 5%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.first-review-content h2 {
  color: white;
  margin-bottom: 20px;
  font-family: "Oswald", sans-serif;
}

.first-review-content p {
  color: white;
  font-family: "ABeeZee", sans-serif;
  font-style: italic;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-size: 16px;
}

.first-review-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.first-review-image img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.first-review-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 150px;
  height: 45px;
  background-color: white;
  border: none;
  border-radius: 20px;
  color: black;
  font-size: 16px;
  cursor: pointer;
  font-family: "Oswald", sans-serif;
  margin-top: 40px;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.first-review-icon {
  color: black;
}

/* Other reviews layout */
.other-reviews {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: stretch;
}

.other-review-item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  flex: 1 1 calc(25% - 20px);
  box-sizing: border-box;
  position: relative;
  transition: transform 0.3s ease;
  background-color: #f4f4f4;
  border-radius: 10px;
}

.other-review-image {
  width: 100%;
  height: 250px;
  transition: transform 0.3s ease;
  border-radius: 10px;
  overflow: hidden;
}

.other-review-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.other-review-title {
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.other-review-title h3 {
  font-family: "Oswald", sans-serif;
  font-size: 18px;
  color: #333;
}

.other-review-date {
  font-family: "PT Serif", serif;
  font-size: 14px;
  color: #666;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .first-review {
    flex-direction: column; /* Stack vertically on medium screens */
    align-items: center;
    padding: 0;
  }

  .first-review-content,
  .first-review-image {
    flex: unset;
    width: 100%; /* Full width for both sections */
    height: auto;
    padding: 0 10px;
  }

  .first-review-image img {
    width: 100%;
    height: auto;
  }

  .other-review-item {
    flex: 1 1 calc(33% - 20px); /* Three items per row */
  }

  .other-review-image {
    height: 200px;
  }
}

@media (max-width: 800px) {
  .first-review-content h2 {
    font-size: 24px; /* Smaller title size */
  }

  .first-review-content p {
    font-size: 14px;
  }

  .first-review-button {
    width: auto;
    padding: 10px 20px; /* Adjust button size for smaller screens */
    font-size: 14px;
  }

  .other-review-item {
    flex: 1 1 calc(50% - 20px); /* Two items per row */
  }

  .other-review-title h3 {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .book-reviews-container {
    padding: 20px;
  }
  .first-review-content {
    padding: 10px;
  }
  .first-review-content h2 {
    font-size: 20px;
  }

  .first-review-content p {
    font-size: 12px;
  }

  .first-review-button {
    width: 100%;
    font-size: 12px;
    height: auto;
    padding: 10px;
  }

  .other-review-item {
    flex: 1 1 100%; /* One item per row */
  }

  .other-review-title h3 {
    font-size: 14px;
  }
}
