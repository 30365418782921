@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;900&family=Oswald:wght@400;700&display=swap");

:root {
  --text-color: #333;
  --primary-color: #ec2121;
  --hover-color: #34495e;
  --button-background: #ec2121;
  --button-hover: #e53939;
}

body {
  font-family: "PT Serif", serif;
}

.book-review-section {
  background-color: var(--background-color);
  padding: 1% 17%; /* Horizontal padding maintained */
}

.BookReview-Container {
  display: flex;
  justify-content: space-between; /* Space books horizontally */
  gap: 2rem; /* Spacing between items */
}

.BookReview-item {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48%; /* Take up half the available width */
}

.BookReview-horizontal-card {
  display: flex;
  align-items: center;
  gap: 1rem; /* Space between image and text */
  background-color: var(--background-color);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.BookReview-image {
  width: 120px; /* Smaller image */
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
}

.BookReview-text {
  flex: 1;
}

.BookReview-title {
  font-size: 18px;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.BookReview-date {
  font-size: 12px;
  margin-bottom: 0.5rem;
  color: #555;
}

.BookReview-description {
  font-size: 14px;
  line-height: 1.4;
  color: var(--text-color);
}

/* Button Styles */
.ReadMore-btn {
  display: inline-block;
  margin-top: 0.5rem;
  padding: 0.5rem 1.5rem;
  background-color: var(--button-background);
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.ReadMore-btn:hover {
  background-color: var(--button-hover);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .BookReview-Container {
    flex-direction: column; /* Stack items vertically for small screens */
    gap: 1.5rem;
  }

  .BookReview-item {
    width: 100%;
  }

  .BookReview-horizontal-card {
    flex-direction: column; /* Stack content inside each card */
    align-items: center;
    text-align: center;
    gap: 0.8rem;
  }

  .BookReview-image {
    width: 150px;
    height: 200px;
  }

  .BookReview-title {
    font-size: 16px;
  }

  .BookReview-date,
  .BookReview-description {
    font-size: 12px;
  }
}
