@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;900&family=Oswald:wght@400;700&display=swap");

.Recent-news-heading {
  margin-left: 17%;
  margin-top: 2%;
  margin-bottom: 5px;
  font-size: 26px;
  color: #ec2121;
  font-family: "PT Serif";
}

.Recent-news-row {
  display: flex;
  margin-left: 17%;
  margin-right: 17%;
  margin-top: -10px;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping of items */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.Recent-news-row::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.Recent-news-item {
  padding: 0 20px;
  position: relative;
  flex: 0 0 auto; /* Prevent items from shrinking */
  text-decoration: none; /* Remove underline from links */
}

.with-divider::after {
  content: "";
  position: absolute;
  right: 0;
  top: 15%; /* Start the divider slightly below the top */
  bottom: 15%;
  width: 1px;
  background-color: black;
}

.Recent-news-Text {
  font-family: "PT Serif", Arial, sans-serif;
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  text-decoration: none; /* Ensure no underline */
  font-weight: 200;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and transform */
}

.Recent-news-Text:hover {
  color: #ec2121;
}

.styled-hr {
  display: block; /* Ensure it behaves as a block-level element */
  margin-left: 17%;
  width: 66%;
  margin-top: -0.01%;
  border: none; /* Remove default border */
  height: 2px; /* Set height of the line */
  border: rgba(0, 0, 0, 0.2) 0.01px solid; /* Semi-transparent thin line */
}
