.team-page-container {
  padding: 3% 10%;
  text-align: center;
  background: linear-gradient(to bottom, #f9f9f9, #eaeaea);
}

.team-title {
  font-size: 3em;
  color: #222;
  margin-bottom: 30px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 25px;
  padding: 30px 0;
}

.team-card {
  background: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.team-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.team-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.team-card:hover .team-image {
  transform: scale(1.05);
}

.team-card-content {
  padding: 20px;
  text-align: center;
}

.team-name {
  font-size: 1.5em;
  font-weight: 700;
  color: #333;
  margin: 10px 0;
}

.team-role {
  font-size: 1.1em;
  color: #777;
  margin: 5px 0;
  font-style: italic;
}

@media (max-width: 768px) {
  .team-title {
    font-size: 2.5em;
  }

  .team-card {
    margin: 0 auto;
  }

  .team-grid {
    gap: 15px;
  }
}
