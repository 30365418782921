@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;900&family=Oswald:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif&display=swap");

/* Base Styles */
.Editorial-Heading {
  padding-left: 17%;
  padding-right: 16%;
  margin-top: 2%;
}

.Editorial-Container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.Editorial-item {
  position: relative;
  width: 50%; /* Take up half of the container */
}

.Urban-title-left {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.Urban-text-left {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.Editorial-image-container {
  display: flex;
  gap: 20px;
  overflow: hidden; /* Prevent overflow on hover */
  transition: transform 0.3s ease; /* Smooth zoom effect */
}

.Editorial-image {
  width: 100%; /* Responsive width */
  height: 250px; /* Maintain aspect ratio */
  object-fit: cover;
  align-content: center;
  border-radius: 15px; /* Adjust the value as needed */
  transition: opacity 0.3s ease; /* Smooth opacity transition */
}

.Editorial-text-overlay {
  position: absolute; /* Position text absolutely within the container */
  bottom: 15px; /* Move the text overlay up by setting bottom to a higher value */
  left: 0;
  width: 100%; /* Span the entire width of the container */
  color: white; /* Text color to contrast with the image */
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent background for better readability */
  padding: 20px;
  box-sizing: border-box;
  text-align: left; /* Align text to the left */
  transition: transform 0.3s ease; /* Smooth movement on hover */
}

.Editorial-image-container:hover .Editorial-text-overlay {
  transform: translateY(-5px); /* Lift text overlay slightly on hover */
}

.Urban-content {
  width: 50%; /* Take up the other half of the container */
  padding-left: 30px;
  box-sizing: border-box;
}

.Urban-Additional-Articles {
  padding: 0px 10px;
}

.Urban {
  display: flex;
  align-items: flex-start; /* Align items at the start of the container */
  margin-bottom: 20px; /* Space between articles */
}

.Urban-Image {
  margin-right: 20px; /* Space between image and text */
}

.Urban-Image-Img {
  width: 300px; /* Adjust size as needed */
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease; /* Smooth zoom effect on hover */
}

.Urban-Content {
  flex: 1; /* Take the remaining space */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  padding-top: 20px;
}

.Urban-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.Urban-date {
  margin-top: -10px;
}

.Editorial-date {
  font-size: 14px;
  color: white;
  margin: 0;
  padding-right: 10px; /* Add a bit of space between date and title */
  text-align: left;
}

/* Responsive Styles */
@media (max-width: 1680px) {
  .Editorial-image-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    overflow: hidden; /* Prevent overflow on hover */
    transition: transform 0.3s ease; /* Smooth zoom effect */
  }

  .Urban-text-left {
    font-size: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  .Editorial-image {
    width: 100%; /* Responsive width */
    height: 150px; /* Maintain aspect ratio */
    object-fit: cover;
    align-content: center;
    transition: opacity 0.3s ease; /* Smooth opacity transition */
  }
}

@media (max-width: 768px) {
  .Editorial-Container {
    flex-direction: column; /* Stack content vertically */
    padding: 10px; /* Adjust padding */
  }

  .Editorial-item,
  .Urban-content {
    width: 100%; /* Full width for mobile */
    padding: 0;
  }

  .Editorial-image {
    height: 200px; /* Reduce image height for smaller screens */
  }

  .Urban-title-left,
  .Urban-title {
    font-size: 30px; /* Decrease font size for title */
  }

  .Urban-text-left,
  .Urban-text {
    font-size: 16px; /* Decrease font size for text */
  }

  .Urban-date,
  .Editorial-date {
    font-size: 12px; /* Decrease font size for dates */
  }

  .Editorial-text-overlay {
    padding: 10px; /* Reduce padding for overlay */
  }
}

@media (max-width: 480px) {
  .Editorial-Heading {
    padding-left: 5%;
    padding-right: 5%;
  }

  .Urban-title-left,
  .Urban-title {
    font-size: 24px; /* Further decrease font size for title */
  }

  .Urban-text-left,
  .Urban-text {
    font-size: 14px; /* Further decrease font size for text */
  }

  .Urban-date,
  .Editorial-date {
    font-size: 10px; /* Further decrease font size for dates */
  }

  .Editorial-image {
    height: 150px; /* Further reduce image height for mobile */
  }
}
