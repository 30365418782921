@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;900&family=Oswald:wght@400;700&display=swap");

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-header {
  font-family: "PT Serif", serif;
  width: 100%;
  align-items: center;
}
.maintenance-banner {
  background-color: #ffcc00;
  color: #000;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
}

/* Header Top: Weather and Social Icons */
.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20%;
  background-color: #f4f4f4;
}

.header-info,
.header-social {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-info span,
.header-social .subscribe-btn,
.header-social .signin-btn {
  font-size: 14px;
}

.icon {
  font-size: 1rem;
  color: black;
}

.subscribe-btn,
.signin-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 12px;
  border: none;
  border-radius: 15px;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
}

.subscribe-btn {
  background-color: #333;
}

.signin-btn {
  background-color: #ec2121;
}

/* Header Main: Logo on Left, Navigation on Right */
.header-main {
  display: flex;
  align-items: center;
  gap: 10%;
  padding: 15px 20%;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  object-fit: contain;
}

.Banner-Container {
  width: 100%;
  height: 100px;
  position: relative;
  overflow: hidden;
}

.Moto-Overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  font-family: "Oswald", sans-serif;
  color: white;
  padding: 20px 30%;
  font-size: 20px;
  box-sizing: border-box;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 100px;
  background: rgba(0, 0, 0, 0.5);
  transition: background 0.3s ease;
}

.banner {
  width: 100%;
  height: 100px;
  object-fit: cover;
  position: relative;
}

.banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  z-index: 3;
}

/* Right-aligned Full-width Navigation */
.nav-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.desktop-nav {
  display: flex;
  padding: 0% 20%;
  gap: 5%;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.nav-link {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  font-weight: 300;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #ec2121;
}

.search-icon {
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.Style-hr {
  border: rgba(0, 0, 0, 0.2) 0.01px solid;
  margin-left: 17%;
  width: 65%;
}

.hamburger {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

/* Dropdown Styling */
.nav-item {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 10;
  flex-direction: column;
  min-width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-item.dropdown:hover .dropdown-menu {
  display: flex;
}

.dropdown-link {
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
  font-weight: 300;
  font-size: 16px;
  transition: background 0.3s, color 0.3s;
}

.dropdown-link:hover {
  background: #ec2121;
  color: white;
}

/* Mobile Navigation Dropdown */
.mobile-nav {
  display: none;
  flex-direction: column;
  align-items: center; /* Center-align for mobile */
  background-color: #f4f4f4;
  padding: 10px;
  width: 100%;
}

.mobile-nav .nav-link {
  padding: 10px 0;
  color: #333;
  text-decoration: none;
  font-weight: 600;
  width: 100%;
  text-align: center; /* Center-align links */
  border-bottom: 1px solid #ccc;
}

.mobile-nav .nav-link:hover {
  color: #ec2121;
}
/* Search Button */
.search-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #333;
  transition: transform 0.3s ease-in-out;
}

.search-button:hover {
  transform: scale(1.1);
}

/* Search Bar */
.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 60px; /* Adjust to align properly under the header */
  right: 0;
  width: 100%;
  max-width: 500px;
  background-color: white;
  padding: 10px 20px;
  border-radius: 30px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Search Input */
.search-input {
  width: 80%;
  padding: 8px 15px;
  border: 1px solid #ccc;
  border-radius: 30px;
  font-size: 16px;
  transition: border 0.3s ease;
}

.search-input:focus {
  border-color: #ec2121;
  outline: none;
}

/* Submit Button */
.search-submit-button {
  padding: 8px 15px;
  background-color: #ec2121;
  color: white;
  border: none;
  border-radius: 20px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-submit-button:hover {
  background-color: #0056b3;
}

/* Media Queries */
@media (max-width: 768px) {
  .header-top {
    flex-direction: column;
    padding: 10px;
    align-items: center; /* Center-align content */
  }

  .header-main {
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    align-items: center; /* Center-align for mobile */
  }

  .desktop-nav {
    display: none;
  }

  .hamburger {
    display: block;
    font-size: 1.8rem;
  }

  .mobile-nav {
    display: none;
    flex-direction: column;
  }

  .mobile-nav.active {
    display: flex;
  }

  .Moto-Overlay {
    font-size: 16px;
    padding: 10px;
    text-align: center; /* Center-align text */
  }

  .banner {
    height: 80px;
  }
}
