.partners-page-container {
  padding: 4% 10%;
  text-align: center;
  background: linear-gradient(135deg, #e0e0e0, #f4f4f4);
  height: 100%;
}

.partners-title {
  font-size: 3rem;
  color: #222;
  margin-bottom: 40px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.partners-scroll-container {
  display: flex;
  overflow-x: auto;
  gap: 40px;
  padding: 20px 0;
  scroll-behavior: smooth;
}

.partner-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: transform 0.3s ease-in-out;
}

.partner-item:hover {
  transform: scale(1.1);
}

.partner-logo {
  width: 180px;
  height: 120px;
  object-fit: contain;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease;
}

.partner-item:hover .partner-logo {
  opacity: 0.8;
  transform: scale(1.05);
}

.partner-name {
  margin-top: 10px;
  font-size: 1.25rem;
  color: #333;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.partner-item:hover .partner-name {
  color: #0077cc;
}

@media (max-width: 768px) {
  .partners-title {
    font-size: 2.5rem;
  }

  .partner-item {
    margin-right: 20px;
  }
}
