@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;900&family=Oswald:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif&display=swap");

.OneonOne-Heading {
  padding-left: 17%;
  padding-right: 16%;
  margin-top: 2%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.OneonOne-story-interviewee {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
}

.OneonOne-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  margin-top: 10px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.first-title {
  padding: 5px 0px;
}

/* Main Container */
.OneonOne-Container {
  display: flex;
  height: 100%;
  margin-top: -10px;
  flex-wrap: wrap; /* Allow items to wrap */
}

.OneonOne-item {
  position: relative;
  width: 100%;
  padding-bottom: 20px; /* Add some space at the bottom */
  border-bottom: rgba(0, 0, 0, 0.5) 0.01px solid;
  margin-bottom: 20px; /* Add some space after the border */
}

/* Image Container */
.OneonOne-image-container {
  margin-top: 10px;
  position: relative;
  width: 30%;
  overflow: hidden; /* Prevent image overflow on hover */
  transition: transform 0.3s ease; /* Smooth zoom effect */
}

.OneonOne-image {
  width: 100%;
  object-fit: cover;
  border-radius: 15px; /* Adjust the value as needed */
  transition: opacity 0.3s ease; /* Smooth opacity transition */
  height: 400px;
}

.OneonOne-image-container:hover .OneonOne-image {
  opacity: 0.9; /* Slightly fade image on hover */
}

/* Additional Content */
.OneonOne-additional-content {
  width: 70%;
  color: black;
  padding: 20px;
  box-sizing: border-box;
  transition: transform 0.3s ease; /* Add a transition for text */
  flex-direction: column; /* Column layout */
  justify-content: space-between; /* Space items */
}

/* Story Sections */
.OneonOne-story {
  padding-bottom: 20px; /* Add some space at the bottom */
  margin-bottom: 20px; /* Add some space after the border */
  border-bottom: rgba(0, 0, 0, 0.5) 0.01px solid;
}

.OneonOne-story-title {
  transition: color 0.3s ease; /* Smooth color transition */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1024px) {
  .OneonOne-image {
    width: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease; /* Smooth opacity transition */
    height: 500px;
  }
}
@media (max-width: 1280px) {
  .OneonOne-image {
    width: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease; /* Smooth opacity transition */
    height: 500px;
  }
}
/* Responsive Styling */
@media (max-width: 768px) {
  .OneonOne-Container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
  }

  .OneonOne-image-container,
  .OneonOne-additional-content {
    width: 100%; /* Full width for mobile */
  }

  .OneonOne-image {
    height: 300px; /* Adjust height for mobile */
  }

  .first-title {
    font-size: 24px; /* Decrease font size for title */
  }

  .OneonOne-description {
    font-size: 16px; /* Decrease font size for description */
  }

  .OneonOne-story-title {
    font-size: 24px; /* Decrease font size for story title */
  }

  .OneonOne-date {
    font-size: 14px; /* Decrease font size for date */
  }
}

@media (max-width: 480px) {
  .OneonOne-Heading {
    padding-left: 5%;
    padding-right: 5%;
  }

  .first-title {
    font-size: 20px; /* Further decrease font size for title */
  }

  .OneonOne-description {
    font-size: 14px; /* Further decrease font size for description */
  }

  .OneonOne-image {
    height: 200px; /* Adjust height for mobile */
  }

  .OneonOne-story-title {
    font-size: 20px; /* Decrease font size for story title on mobile */
    white-space: nowrap; /* Prevent wrapping on mobile */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add ellipsis */
  }

  .OneonOne-date {
    font-size: 12px; /* Decrease font size for date */
  }
}
