@import url("https://fonts.googleapis.com/css2?family=PT+Serif&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

.detail-page {
  margin: 0 auto;
  padding: 0% 25%;
  font-family: Arial, sans-serif;
}

.detail-title {
  margin-bottom: 10px;
}

.detail-date {
  font-size: 0.9em;
  color: gray;
  margin-bottom: 20px;
}

.detail-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.detail-description {
  margin-bottom: 30px;
}

.related-articles ul {
  list-style-type: none;
  padding: 0;
}

.related-articles li {
  margin: 5px 0;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  transition: transform 0.2s ease;
}

.related-articles a {
  text-decoration: none;
  font-size: 18px;
}

.related-articles hr {
  border-width: 2px;
}

.related-articles a:hover {
  text-decoration: underline;
}

.related-articles li:last-child {
  border-bottom: none;
}

/* Author and Share Section */
.author-share-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}

.author-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.author-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.author-name {
  font-size: 16px;
  font-weight: bold;
}

.share-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.share-text {
  font-size: 14px;
  font-weight: bold;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons a {
  color: #555;
  font-size: 20px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #000;
}

/* Comment Section Styling */
.comment-section {
  margin-top: 40px;
  padding: 25px;
  border-top: 2px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Comment Title */
.comment-section h2 {
  font-family: "Oswald", sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  text-align: left;
}

/* Textarea Input */
.comment-section textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  resize: none;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.comment-section textarea:focus {
  border-color: #d1242a;
  outline: none;
  box-shadow: 0 0 6px rgba(209, 36, 42, 0.4);
}

/* Buttons */
.Post-button {
  padding: 8px 15px;
  background-color: #d1242a;
  font-size: 14px;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;
  margin: 8px 0px;
}

.comment-section button:hover {
  color: black;

  transform: scale(1.03);
}

/* Comments List */
.comment-list {
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.comment-card {
  padding: 12px;
  margin-bottom: 10px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.comment-card:hover {
  transform: translateY(-2px);
}

/* Comment Author */
.comment-author {
  font-weight: bold;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
}

.user-icon {
  font-size: 18px;
  margin-right: 5px;
  color: #666;
}

/* Comment Text */
.comment-text {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
  line-height: 1.4;
}

/* Reply Button */
.text-btn {
  background: none;
  border: none;
  color: #d1242a;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  transition: color 0.2s ease-in-out;
  display: block;
}

.text-btn:hover {
  color: #b71c1c;
}

/* Reply Section */
.reply-section {
  margin-top: 15px;
  padding: 12px;
  border-left: 3px solid #d1242a;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.reply-section textarea {
  width: 100%;
  height: 60px;
  padding: 8px;
  font-size: 13px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}

/* Reply Button */
.reply-section button {
  margin-top: 10px;
  font-size: 14px;
  padding: 8px 20px;
  background-color: #333;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.reply-section button:hover {
  background-color: #555;
}

/* Reply List */
.replies {
  margin-top: 15px;
  padding-left: 20px;
}

.replies .comment-card {
  background-color: #f1f1f1;
  border-left: 3px solid #d1242a;
  padding: 10px;
}

.replies .comment-author {
  font-size: 13px;
}

.replies .comment-text {
  font-size: 13px;
}
/* Nested Replies */
.nested-reply {
  margin-top: 10px;
  margin-left: 30px; /* Indent nested replies */
  padding: 10px;
  background-color: #f8f8f8;
  border-left: 3px solid #d1242a;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.nested-reply .reply-author {
  font-weight: bold;
  font-size: 13px;
  color: #333;
}

.nested-reply .user-icon {
  font-size: 16px;
  margin-right: 5px;
  color: #888;
}

.nested-reply p {
  font-size: 14px;
  color: #444;
  margin-top: 5px;
  line-height: 1.4;
}

/* Adjust spacing for deeper nested replies */
.nested-reply .nested-reply {
  margin-left: 40px; /* Increase indentation */
  background-color: #f1f1f1;
  border-left: 2px solid #b71c1c;
}


/* Mobile Responsiveness */
@media (max-width: 768px) {
  .detail-page {
    padding: 0% 10%;
  }

  .comment-section {
    padding: 15px;
  }

  .comment-section h2 {
    font-size: 20px;
  }

  .comment-section button {
    font-size: 13px;
    padding: 7px 12px;
  }

  .comment-section textarea {
    font-size: 13px;
    height: 80px;
  }
  .nested-reply {
    margin-left: 20px;
    padding: 8px;
  }

  .nested-reply .reply-author {
    font-size: 12px;
  }

  .nested-reply p {
    font-size: 13px;
  }

  .nested-reply .nested-reply {
    margin-left: 30px;
  }
}
