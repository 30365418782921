@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");

.cover-story-wrapper {
  display: grid;
  grid-template-columns: 75% 30%;
  width: 100%;
  padding-left: 17%;
  padding-right: 22%;
  align-items: center;
}
.slider {
  height: 50%;
}
.slide-container {
  position: relative;
  height: 100%;
  border-radius: 15px; /* Add rounded corners to the container */
  overflow: hidden; /* Ensures that child elements stay within the border */
}

.slider-card-image {
  width: 100%;
  height: 500px;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 15px; /* Round the image corners to match container */
}

/* Darkening effect */
.slider-card-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Ensures overlay remains at the bottom of the image */
.title-overlay {
  position: absolute;
  font-family: "Merriweather";
  width: 100%;
  bottom: 0%; /* Fixes overlay to bottom of the image */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(
    255,
    255,
    255,
    1
  ); /* Slightly darker for readability */
  padding: 15px 20px;
  font-weight: 700;
  text-align: center;
  box-sizing: border-box;
  z-index: 2; /* Ensures text overlay is above image effects */
  border-bottom-left-radius: 15px; /* Curved bottom left corner */
  border-bottom-right-radius: 15px; /* Curved bottom right corner */
}

.title-overlay h2 {
  margin: 0;
  color: black;
  font-size: 22px;
}

.magazine-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-left: 4%;
  transition: transform 0.3s ease;
}

.magazine-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .cover-story-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding-left: 5%; /* Adjust padding for smaller screens */
    padding-right: 5%;
  }

  .slider-container,
  .image-container {
    width: 100%;
    margin: 0;
  }

  .slide-container {
    height: auto;
    min-height: 400px;
    border-radius: 15px; /* Add rounded corners for smaller screens */
  }

  .slider-card-image {
    height: auto;
    max-height: 400px;
    border-radius: 15px; /* Round the image corners */
  }

  .title-overlay {
    padding: 10px;
    font-size: 24px;
    width: 100%;
    bottom: 0; /* Keeps overlay at the bottom */
  }

  .title-overlay h2 {
    font-size: 24px;
  }

  .magazine-image {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
    padding-right: 0;
  }
}

@media (max-width: 480px) {
  .cover-story-wrapper {
    padding-left: 3%;
    padding-right: 3%;
  }

  .title-overlay {
    padding: 5px;
    font-size: 18px;
  }

  .title-overlay h2 {
    font-size: 20px;
  }

  .slider-card-image {
    height: auto;
    max-height: 300px; /* Reduce image height further */
    border-radius: 15px; /* Round the image corners */
  }

  .magazine-image {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
    border-radius: 15px; /* Round the image corners */
  }
}
