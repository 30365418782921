.sac-summit-container {
  width: 100%;
  overflow-x: hidden; /* Hide the scrollbar */

  position: relative;
}

.sac-summit-scroller {
  display: flex;
  flex-direction: row;
  gap: 15px; /* Adds space between the images */
  overflow-x: hidden; /* Allow horizontal scrolling when necessary */
  scroll-behavior: smooth; /* Smooth scrolling effect */
  white-space: nowrap; /* Prevent the content from wrapping */
}

.sac-summit-image {
  margin-top: 10px;
  width: 100%; /* Make images responsive */
  max-width: 500px; /* Set a max width for larger screens */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
  border-radius: 10px; /* Optional: adds rounded corners */
  transition: transform 0.3s; /* Optional: smooth hover effect */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .sac-summit-image {
    max-width: 80%; /* Adjust the max width for smaller screens */
  }
}

@media (max-width: 480px) {
  .sac-summit-image {
    max-width: 100%; /* Full width on very small screens */
  }
}
