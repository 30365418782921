@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");

#Signup-Title {
  font-family: "Merriweather", sans-serif;
  font-weight: 700;
  font-size: 35px;
  text-align: center;
  margin-bottom: 20px;
}

.create-account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.create-account-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 400px;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-group label {
  font-size: 1rem;
  margin-bottom: 5px;
}

.form-group input {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.create-account-button {
  width: 100%;
  padding: 12px;
  background-color: #d1242a;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-account-button:hover {
  background-color: black;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.continue-container {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.continue-container hr {
  border-width: 1px;
  width: 100%;
}

.google-signin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 80%;
  padding: 10px;
  background-color: white;
  color: black;
  border: 2px solid black;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.google-signin-button:hover {
  background-color: black;
  color: white;
}

.signin-link {
  display: flex;
  align-items: center;
  gap: 10px;
}

.signin-link a {
  text-decoration: none;
  color: blue;
}
