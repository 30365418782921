@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;900&family=Oswald:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

/* Base Styles */
.Velocity-Heading {
  padding-left: 17%;
  padding-right: 16%;
  margin-top: 2%;
}

.Velocity-Container {
  display: flex;
  gap: 20px; /* Space between items */
  overflow-x: hidden; /* Hide scrollbar */
  scroll-behavior: smooth; /* Smooth scrolling */
  padding: 0px 20px;
  position: relative;
}

.Velocity-Container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

.Velocity-item {
  flex: 0 0 300px; /* Each item takes up a fixed width */
  position: relative;
  width: 400px; /* Fixed width for items */
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.Velocity-image-container {
  position: relative;
}

.Velocity-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.Velocity-text-overlay {
  padding: 15px;
  text-align: left;
}

.Velocity-text {
  margin-top: 10px;
  font-weight: bold;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Velocity-date {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #777;
  margin-top: 5px;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .Velocity-Heading {
    padding: 0% 5%;
  }
  .Velocity-Container {
    gap: 10px; /* Reduce gap for smaller screens */
  }

  .Velocity-item {
    flex: 0 0 250px; /* Adjust item width for tablets */
  }

  .Velocity-text {
    font-size: 18px; /* Reduce font size */
  }

  .Velocity-image {
    height: 180px; /* Adjust image height */
  }
}

@media (max-width: 480px) {
  .Velocity-item {
    flex: 0 0 200px; /* Adjust item width for small screens */
  }

  .Velocity-text {
    font-size: 16px; /* Further reduce font size */
  }

  .Velocity-image {
    height: 150px; /* Reduce image height */
  }
}
