@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;900&family=Oswald:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
.Section-Headings {
  color: #ec2121;
  font-family: "PT Serif";
  font-size: 13px;
}
.Section-Titles {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Merriweather";
  font-weight: 700;
  font-size: 22px;
}
.Section-Text {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Merriweather Sans";
  font-size: 13px;
  font-weight: 200;
}
.Section-Dates {
  font-family: "Merriweather Sans";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
.Section-Styled-hr {
  border: rgba(0, 0, 0, 0.3) 0.01px solid;
  width: 100%;
  margin-top: 5px;
}
/* Add this to your CSS file */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh; /* Full height of the viewport */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ec2121; /* Color of the spinner */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
