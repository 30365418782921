@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

/* Main header container */
.min-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f5f5f5;
  padding: 10px 0;
}

/* Header menu styling */
.min-header-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 5%;
}

/* Hamburger icon styling with hover effect */
.min-hamburger-icon {
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.min-hamburger-icon:hover {
  transform: scale(1.2);
  color: #ec2121;
}

/* Logo and title container styling */
.min-logo-title {
  display: flex;
  align-items: center;
  margin-left: 12%;
}

.min-logo-image {
  width: 50px;
  height: auto;
  margin-right: 10px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.min-title {
  display: flex;
  flex-direction: row;
}

.min-urban-text,
.min-update-text {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  color: #ec2121;
  margin: 0;
}

.min-update-text {
  font-weight: 400;
}

/* Button styling */
.min-header-buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 20px;
  background-color: #333;
  border: none;
  border-radius: 20px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.min-header-buttons button:hover {
  background-color: #444;
  transform: scale(1.05);
}

/* Side menu styling */
.side-menu {
  position: fixed;
  top: 0;
  left: -300px; /* Initially hidden off-screen */
  height: 100%;
  width: 300px;
  background-color: white;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  transition: left 0.3s ease;
}

.side-menu.open {
  left: 0; /* Slide into view */
}

.side-menu-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.side-menu-logo {
  width: 40px;
  height: auto;
  margin-right: 15px;
  transition: transform 0.3s ease;
}

.side-menu-title {
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  color: #ec2121;
  font-weight: 700;
}

.side-menu-items {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.side-menu-items li {
  cursor: pointer;
  padding: 12px 15px;
  font-size: 18px;
  color: #333;
  font-family: "Roboto", sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 10px;
}

.side-menu-items li:hover {
  background-color: #ec2121;
  color: white;
}

.close-menu-btn {
  background: none;
  border: none;
  color: #ec2121;
  font-size: 30px;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.close-menu-btn:hover {
  color: black;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .min-hamburger-icon {
    font-size: 24px;
  }

  .min-urban-text,
  .min-update-text {
    font-size: 20px;
  }

  .side-menu {
    width: 250px;
  }
}

@media (max-width: 480px) {
  .min-urban-text,
  .min-update-text {
    font-size: 18px;
  }

  .min-logo-image {
    width: 40px;
  }

  .side-menu {
    width: 200px;
  }
}
