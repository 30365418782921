@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;900&family=Oswald:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");

.Cover-Story-Heading {
  padding-left: 17%;
  padding-right: 17%;
  margin-top: 2%;
}

.Styled-hr-coverstory {
  border: rgba(0, 0, 0, 0.2) 0.01px solid; /* Semi-transparent thin line */
  margin-top: 5px;
}

.Cover-Story-Container {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  flex-wrap: wrap;
}

.Cover-Story-item {
  position: relative;
  width: 50%;
  min-width: 300px;
  max-height: 600px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.Cover-Story-image-container {
  position: relative;
  flex: 1 0 auto;
  transition: transform 0.3s ease;
}

.Cover-Story-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px; /* Adjust the value as needed */
  transition: opacity 0.3s ease;
}
.Cover-Story-Title-Overlay {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.Cover-Story-para-overlay {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-top: 0;
  text-overflow: ellipsis;
}
.Cover-Story-text-overlay {
  position: absolute;
  bottom: 0%;
  left: 0;
  font-size: 12px;
  width: 100%;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 40px;
  box-sizing: border-box;
  text-align: left;
  transition: background 0.3s ease;
  max-height: 350px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.Cover-Story-date {
  margin-top: -10px;
}

.Cover-Story-additional-content {
  width: 50%;
  padding: 0px 20px;
  max-height: 600px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
  overflow: hidden;
}

.Cover-Story-additional-items {
  position: relative;
  padding: 10px 0; /* Spacing within each item */
  transition: transform 0.3s ease, color 0.3s ease;
  max-height: 250px;
  overflow: hidden;
}
/* Add a dotted line only after the first additional item */
.Cover-Story-additional-items:not(:last-child)::after {
  content: "";
  display: block;
  margin-top: 20px; /* Space above the dotted line */
  border-top: rgba(0, 0, 0, 0.5) 0.01px solid; /* Dotted line style */
  width: 100%;
}

.Side-cover-story-title {
  transition: color 0.3s ease, transform 0.3s ease;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Side-cover-story-text {
  margin-top: 10px;
  transition: color 0.3s ease, transform 0.3s ease;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Side-cover-story-author {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .Cover-Story-Heading {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 15px;
  }

  .Cover-Story-item,
  .Cover-Story-additional-content {
    width: 100%;
    padding: 0;
    max-height: 400px;
  }

  .Cover-Story-image {
    height: 100%;
  }

  .Styled-hr {
    margin: 20px 0;
  }

  .Side-cover-story-title {
    font-size: 24px;
  }
}
