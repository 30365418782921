@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;900&family=Oswald:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif&display=swap");

.main-container {
  display: flex;
  flex-direction: row;
}
.LeaderSpeak-Heading {
  padding-left: 17%;
  margin-top: 3%;
  width: 50%;
}

/* Container and Layout */
.LeaderSpeak-Container {
  display: flex; /* Use flexbox for horizontal layout */
  width: 100%; /* Make the container full width */
}

.LeaderSpeak-content {
  flex: 1; /* Allow this section to grow */
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  gap: 20px;
}

.LeaderSpeak-item {
  width: 100%; /* Use full width of the parent container */
  overflow: hidden; /* Prevent image overflow on hover */
}

.LeaderSpeak-image-container {
  position: relative;
  transition: transform 0.3s ease; /* Smooth zoom effect */
}

.LeaderSpeak-image {
  width: 100%;
  border-radius: 15px; /* Adjust the value as needed */
  height: 250px; /* Set default height */
  object-fit: cover;
  transition: transform 0.3s ease;
}

.LeaderSpeak-text-overlay {
  box-sizing: border-box;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Additional content styling */
.LeaderSpeak-additional-content {
  width: 100%;
  display: flex;
  height: 100%;
  padding-right: 5%;
  flex-direction: column;
  justify-self: space-between;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.image-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Tab-titles {
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth color and scale transition */
}
.tab-content-title {
  font-family: "Merriweather", sans-serif;
  font-size: 22px;
}
.li-item {
  border-bottom: rgba(0, 0, 0, 0.5) 0.01px solid;
  padding: 10px 0px;
}

.li-item:last-child {
  border-bottom: 0px solid #ccc;
}

.LeaderSpeak-additional-content .additional-item-container {
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease, color 0.3s ease;
  border-bottom: 2px solid #ccc; /* Line between items */
}
/* Remove border from only the last item */
.LeaderSpeak-additional-content .additional-item-container:not(:last-of-type) {
  border-bottom: 2px solid #ccc;
}

.LeaderSpeak-additional-content .additional-item-container:last-of-type {
  border-bottom: none;
}
/* Date and Title Styling */
.leaderSpeak-date {
  margin-bottom: 5px;
  transition: color 0.3s ease; /* Smooth color transition */
}

.Side-leaderSpeak-title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

/* Tab Section */
.Tab-Section {
  width: 34%; /* Ensure the tab section takes 40% of the container */
  padding-left: 20px; /* Optional padding for the tab section */
}

.Tab-Container {
  display: flex; /* Flexbox for horizontal alignment */
  gap: 20px; /* Space between tabs */
  margin-bottom: 15px; /* Space below the tab container */
  margin-top: -9.5%;
  position: relative; /* Needed for absolute positioning of the underline */
}

.Tab {
  font-family: "PT Serif";
  color: rgba(0, 0, 0, 0.6);
  font-weight: 800;
  font-size: 26px; /* Tab font size */
  padding: 0px 15px; /* Padding for better touch area */
  padding-top: 15px;
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor on hover */
  background-color: white;
  margin-top: 16%;
  position: relative; /* Needed for absolute positioning of the underline */
}

.Tab.active {
  color: #ec2121; /* Active tab text color */
}

.Tab-Content {
  padding: 0px 10px; /* Padding for tab content area */
  border-radius: 4px; /* Slightly rounded corners */
  border-left: rgba(0, 0, 0, 0.2) 0.01px solid; /* Optional: border to separate from content */
}

.Tab-Content ul {
  list-style: none; /* Remove bullets from unordered list */
  padding: 0px; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

/* Responsive Styling */
@media (max-width: 1024px) {
  .LeaderSpeak-Container {
    flex-direction: column; /* Stack items vertically */
    gap: 20px; /* Space between stacked items */
  }

  .Tab-Section {
    width: 100%; /* Full width on medium screens */
  }
}

@media (max-width: 768px) {
  .main-container {
    display: flex;
    flex-direction: column;
  }
  .LeaderSpeak-Container {
    flex-direction: column; /* Stack items vertically */
    gap: 40px; /* Space between stacked items */
  }

  .LeaderSpeak-content,
  .Tab-Section {
    width: 100%; /* Full width on small screens */
  }

  .LeaderSpeak-item {
    width: 100%; /* Full width on small screens */
    margin-right: 0;
  }

  .LeaderSpeak-additional-content {
    width: 100%;
    margin-left: 0;
  }

  .LeaderSpeak-image {
    height: 200px; /* Adjusted height for tablets */
  }

  .leaderSpeak-date {
    font-size: 14px; /* Adjusted font size */
  }

  .Side-leaderSpeak-title {
    font-size: 24px; /* Adjusted font size */
  }
  .LeaderSpeak-Heading {
    padding-left: 5%;
    padding-right: 5%;
    font-size: 13px; /* Smaller heading size */
    margin-top: 30px; /* Reduced margin */
    width: 100%; /* Full width on mobile */
  }

  .Tab {
    font-size: 24px; /* Smaller font size for tabs on mobile */
    padding: 8px 10px; /* Adjusted padding for mobile */
  }

  .Tab-Underline {
    bottom: 0;
    height: 2px; /* Smaller underline on mobile */
  }
}

@media (max-width: 425px) {
  .LeaderSpeak-item {
    width: 100%;
    margin-right: 0;
  }

  .LeaderSpeak-additional-content {
    width: 100%;
    margin-left: 0;
  }

  .LeaderSpeak-image {
    height: 200px; /* Adjusted height for mobile */
  }

  .Side-leaderSpeak-title {
    font-size: 20px; /* Adjusted font size for mobile */
  }
}
