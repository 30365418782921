/* src/components/UpcomingEvents.css */
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.upcoming-events-heading-container {
  padding: 0 13%;
  display: flex;
  gap: 20px;
  width: 100%;
}
.upcoming-events-heading {
  font-size: 22px;
}

.upcoming-events-container {
  display: flex;
  padding: 20px 17%;
  gap: 20px; /* Space between large and small images */
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.big-image {
  flex: 1;
  min-width: 300px; /* Minimum width for the big image */
}

.big-image img {
  width: 100%;
  height: 600px; /* Height for larger screens */
  object-fit: cover;
  border-radius: 8px; /* Optional: rounded corners */
}

.small-images {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between small images */
}

.small-image {
  flex: 1;
}

.small-image img {
  width: 100%;
  height: 300px; /* Height for small images */
  object-fit: cover;
  border-radius: 8px; /* Optional: rounded corners */
}

.More-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.More-Container a {
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  color: black;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .upcoming-events-heading {
    font-size: 20px; /* Smaller font size for smaller screens */
  }

  .big-image img {
    height: 400px; /* Adjust height for smaller screens */
  }

  .small-image img {
    height: 200px; /* Adjust height for smaller images */
  }

  .upcoming-events-container {
    flex-direction: column; /* Stack images vertically on smaller screens */
    align-items: center; /* Center align items */
  }
}

@media (max-width: 480px) {
  .upcoming-events-heading {
    font-size: 18px; /* Even smaller font size for mobile */
  }

  .big-image img {
    height: 300px; /* Further reduce height for mobile */
  }

  .small-image img {
    height: 150px; /* Further reduce height for small images */
  }

  .More-Container a {
    font-size: 18px; /* Smaller font size for links */
  }
}
