@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;900&family=Oswald:wght@400;700&display=swap");

/* Base Styles */
.City-Heading {
  padding-left: 17%;
  padding-right: 16%;
  margin-top: 2%;
}

.City-Container {
  display: flex;
  padding: 0px;
}

.City-item {
  max-height: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.City-image-container {
  position: relative;
  flex: 0 auto;
  transition: transform 0.3s ease;
  height: 350px; /* Set fixed height for the container */
}

.City-image {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.City-text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  font-size: 15px;
  box-sizing: border-box;
  text-align: left;
  transition: background 0.3s ease;
  max-height: 150px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.City-date {
  font-size: 14px;
  color: white;
  margin: 0;
  padding-right: 10px;
  text-align: left;
}

.Articles-content {
  width: 55%;
  padding-left: 30px;
  box-sizing: border-box;
}

.Article {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.Article-Image {
  margin-right: 20px;
}

.Article-Image-Img {
  width: 300px;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 15px;
}

.Article-Content {
  flex: 1;
  margin-top: -50px;
  padding-bottom: 10px;
}

.Article-title {
  margin-top: 0px;
  transition: color 0.3s ease;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Article-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-top: 10px;
  text-overflow: ellipsis;
}

.Article-date {
  margin-top: 50px;
  padding-right: 10px;
  text-align: left;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .City-Heading {
    padding-left: 10%;
    padding-right: 10%;
    font-size: 16px; /* Adjusted font size */
  }

  .City-Container {
    flex-direction: column;
    padding: 10px;
  }

  .City-item,
  .Articles-content {
    width: 100%;
  }

  .City-image-container {
    height: 300px;
  }

  .City-image {
    height: 100%;
  }

  .City-text-overlay {
    padding: 10px;
    font-size: 14px;
  }

  .City-date {
    font-size: 12px;
  }

  .Articles-content {
    padding: 0;
  }

  .Article-Image-Img {
    width: 100%;
    height: auto;
  }

  .Article-title {
    font-size: 20px;
  }

  .Article-text {
    font-size: 14px;
  }

  .Article-date {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .City-Heading {
    padding-left: 5%;
    padding-right: 5%;
    font-size: 14px;
  }

  .City-image-container {
    height: 250px;
  }

  .City-image {
    border-radius: 10px;
  }

  .City-text-overlay {
    font-size: 12px;
    padding: 8px;
  }

  .City-date {
    font-size: 10px;
  }

  .Article {
    flex-direction: column;
  }

  .Article-title {
    font-size: 18px;
  }

  .Article-text {
    font-size: 12px;
  }

  .Article-date {
    font-size: 10px;
  }

  .Article-Image {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .Article-Content {
    margin-top: 0;
  }
}
