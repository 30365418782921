.profile-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    text-align: center;
    padding-left: 17%;
    padding-right: 17%;
  }

  
  .profile-info {
    text-align: left;
    padding: 15px 20px;
  }
  
  .profile-info p {
    font-size: 18px;
    margin: 10px 0;
    color: #555;
  }
  
  .profile-info strong {
    color: #222;
  }
  
  .logout-button {
    background: #e74c3c;
    color: white;
    border: none;
    padding: 12px 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 6px;
    margin-top: 20px;
    transition: background 0.3s ease;
  }
  
  .logout-button:hover {
    background: #c0392b;
  }
  
  .latest-magazines-section {
    margin: 50px auto;
    text-align: center;
    padding-left: 17%;
    padding-right: 17%;
  }
  
  .latest-magazines-heading {
    font-size: 26px;
    color: #333;
  }
  
  .magazines-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .magazine-card {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 220px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .magazine-card:hover {
    transform: translateY(-5px);
  }
  
  .magazine-cover {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .magazine-title {
    font-size: 18px;
    color: #222;
    margin-top: 10px;
  }
  
  .magazine-description {
    font-size: 14px;
    color: #555;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
  }
  