/* Sign-in form container */
.signin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  text-align: center;
  width: 100%;
}

.signin-title {
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: "PT Serif", serif;
}

.signin-form {
  width: 100%;
  max-width: 400px;
}

.form-group {
  width: 100%;
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.signin-button {
  background-color: #ec2121;
  color: white;
  border: none;
  width: 100%;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  padding: 10px;
  font-weight: 700;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;
}

/* Divider */
.continue-container {
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.continue-container hr {
  flex-grow: 1;
  border: none;
  border-top: 1px solid #ccc;
  margin: 0 10px;
}

/* Social Sign-in */
.social-signin {
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
}

.google-signin-button {
  width: 100%;
  padding: 10px;
  background-color: #4285f4;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon {
  margin-right: 10px;
  font-size: 1.2rem;
}

.signup-link {
  margin-top: 20px;
  font-size: 0.9rem;
}

.signup-link a {
  color: #ec2121;
  text-decoration: none;
  font-weight: bold;
}
